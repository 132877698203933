import {
	mdiAccountVoice,
	mdiHammerWrench,
	mdiHome,
	mdiImageAlbum,
	mdiMessage,
	mdiNewspaperVariantMultipleOutline,
	mdiPaletteOutline,
	mdiViewCompact,
} from '@mdi/js'

// Array of sections

export default [
	{
		icon: mdiHome,
		title: 'Dashboard',
		to: 'agency-dashboard',
	},
	{
		title: 'Marketing',
		icon: mdiViewCompact,
		children: [
			{
				title: 'Community',
				children: [
					{
						title: 'menu.agency.marketing.highlights',
						to: 'agency-marketing-highlights-list',
					},
					{
						title: 'menu.agency.marketing.highlightBannersApp',
						to: 'agency-marketing-highlight-banners-list',
					},
					/* {
							title: 'menu.agency.marketing.highlightBannersWeb',
							to: '#',
						}, */
				],
			},
			/* {
				title: 'Jobs',
				children: [
					{
						title: '#',
						to: '#',
					}
				],
			}, */
			{
				title: 'TV',
				children: [
					{
						title: 'menu.agency.marketing.tvBanners',
						to: 'agency-marketing-tv-banners-list',
					},
				],
			},
			{
				title: 'menu.agency.marketing.providers',
				to: 'agency-marketing-providers-list',
			},
			{
				title: 'menu.agency.marketing.campaigns',
				to: 'agency-marketing-campaigns-list',
			},
		],
	},
	{
		icon: mdiImageAlbum,
		title: 'menu.magazines',
		to: 'agency-magazine-list',
	},
	{
		icon: mdiMessage,
		title: 'menu.messages',
		to: 'agency-messages-list',
	},
	{
		icon: mdiAccountVoice,
		title: 'menu.agency.contactMessages',
		to: 'contact-requests-list',
	},
	{
		title: 'Newsletter',
		icon: mdiNewspaperVariantMultipleOutline,
		children: [
			{
				title: 'Newsletter',
				to: 'agency-newsletter-list',
			},
			{
				title: 'menu.agency.newsletter.campaigns',
				to: 'agency-newsletter-campaigns-list',
			},
			{
				title: 'menu.agency.newsletter.categories',
				to: 'agency-newsletter-categories-list',
			},
			{
				title: 'menu.agency.newsletter.periodicities',
				to: 'agency-newsletter-periodicities-list',
			},
		],
	},
	{
		title: 'menu.agency.services',
		icon: mdiHammerWrench,
		children: [
			{
				title: 'menu.agency.services',
				to: 'agency-services-list',
			},
			{
				title: 'menu.agency.categories',
				to: 'services-categories-list',
			},
			{
				title: 'menu.agency.audience',
				to: 'agency-target-audiences-list',
			},
		],
	},
	{
		title: 'menu.themes.themes',
		icon: mdiPaletteOutline,
		children: [
			{
				title: 'menu.themes.appThemes',
				to: 'app-themes-list',
			},
			{
				title: 'menu.themes.projectsVariables',
				to: 'projects-variables-list',
			},
		],
	},
]
