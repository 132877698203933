import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

// // COUNTRIES
// // // LIST ALL COUNTRIES
export const countriesList = (params = { page: 1, filter: '', language: '' }) =>
	Http.get(`/countries${serialize(params)}`)
// // // GET COUNTRY BY ID
export const countriesByID = (params = '') => Http.get(`/countries/${params}`)
// // // POST NEW COUNTRY
export const newCountry = (params = '') => Http.post('/countries', params)
// // // UPDATE COUNTRY
export const updateCountry = (params = '') => Http.put(`/countries/${params.id}`, params)
// // // DELETE COUNTRY
export const deleteCountryByID = (params = '') => Http.delete(`/countries/${params.id}`)

// // CITIES
// // // LIST ALL CITIES
export const citiesList = (params = '') => Http.get(`/countries-cities${serialize(params)}`)
export const cityCreate = (params = '') => Http.post('/countries-cities', params)
export const cityByID = (params = '') => Http.get(`/countries-cities/${params}`)
export const cityUpdate = (params = '') => Http.put(`/countries-cities/${params.id}`, params)
export const cityDestroy = (params = '') => Http.delete(`/countries-cities/${params.id}`)

// // NATIONALITIES
export const nationalityCreate = (params = '') => Http.post('/countries-nationalities', params)
// // // LIST ALL NATIONALITIES
export const nationalitiesList = (params = '') => Http.get(`/countries-nationalities${serialize(params)}`)
// // // GET NATIONALITy BY ID
export const nationalityByID = (params = '') => Http.get(`/countries-nationalities/${params}`)
// // // UPDATE NATIONALITY
export const nationalityUpdate = (params = '') => Http.put(`/countries-nationalities/${params.id}`, params)
// // // DELETE NATIONALITY
export const nationalityDestroy = (params = '') => Http.delete(`/countries-nationalities/${params.id}`)

// // REGIONS
export const regionCreate = (params = '') => Http.post('/countries-regions', params)
export const regionsList = (params = '') => Http.get(`/countries-regions${serialize(params)}`)
export const regionByID = (params = '') => Http.get(`/countries-regions/${params}`)
export const regionUpdate = (params = '') => Http.put(`/countries-regions/${params.id}`, params)
export const regionDestroy = (params = '') => Http.delete(`/countries-regions/${params.id}`)

// // STATES
export const stateCreate = (params = '') => Http.post('/countries-states', params)
export const statesList = (params = '') => Http.get(`/countries-states${serialize(params)}`)
export const stateByID = (params = '') => Http.get(`/countries-states/${params}`)
export const stateUpdate = (params = '') => Http.put(`/countries-states/${params.id}`, params)
export const stateDestroy = (params = '') => Http.delete(`/countries-states/${params.id}`)

// // TIMEZONES
export const timezoneCreate = (params = '') => Http.post('/timezone', params)
// // // LIST ALL TIMEZONES
export const timezonesList = (params = { perPage: null, page: null, search: '' }) =>
	Http.get(`/timezone${serialize(params)}`)
// // // GET TIMEZONE BY ID
export const timezoneByID = (params = '') => Http.get(`/timezone/${params}`)
// // // UPDATE TIMEZONE
export const timezoneUpdate = (params = '') => Http.put(`/timezone/${params.id}`, params)
// // // DELETE TIMEZONE
export const timezoneDestroy = (params = '') => Http.delete(`/timezone/${params.id}`)

export const searchCity = (params = { search: ''}) =>
	Http.get(`/search-city/${params.search}/${serialize(params)}`)

//SEARCH STATE USING GEODB
export const searchState = (params = '') => Http.get(`/selects/states${serialize(params)}`)
