import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const searchGame = ({ lang, term }) => Http.get(`${lang}/games/search?q=${term}`)

export const getGames = (
	params = {
		per_page: 10,
		page: 1,
		'filter[term]': '',
	},
) => Http.get(`/games/fetch${serialize(params)}`)

export const destroyGame = (
	params = {
		gameSlug: null,
	},
) => Http.delete(`/games/${params.gameSlug}/destroy`)

export const saveGame = (
	params = {
		name: null,
	},
) => Http.post(`/games/store`, params)

export const updateGame = (
	params = {
		gameSlug: null,
		name: null,
	},
) => Http.put(`/games/${params.gameSlug}/update`, params)

export const showGame = (
	params = {
		gameSlug: null,
	},
) => Http.get(`/games/${params.gameSlug}/show`)

export const batchUpsertGamesGame = (
	params = {
		gameSlug: null,
		games_id: null,
	},
) => Http.post(`/games/batch/games/${params.gameSlug}/upsert`, params)

export const destroyGameGame = (
	params = {
		gameId: null,
	},
) => Http.delete(`/games/games/${params.gameSlug}/destroy`, { data: { game_id: params.gameId } })
