const financial = [
	{
		path: '/network/financial/banks/list',
		name: 'network-financial-banks-list',
		component: () => import('@/views/network/financial/banks/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/financial/cupons/list',
		name: 'network-financial-cupons-list',
		component: () => import('@/views/network/financial/cupons/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/financial/currencies/list',
		name: 'network-financial-currencies-list',
		component: () => import('@/views/network/financial/currencies/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/financial/expenses-categories/list',
		name: 'network-financial-expenses-categories-list',
		component: () => import('@/views/network/financial/expenses-categories/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/financial/invoices/list',
		name: 'network-financial-invoices-list',
		component: () => import('@/views/network/financial/invoices/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/financial/invoices/create',
		name: 'network-financial-invoices-create',
		component: () => import('@/views/network/financial/invoices/new.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/financial/payment-methods/list',
		name: 'network-financial-payment-methods-list',
		component: () => import('@/views/network/financial/payment-methods/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/financial/payment-fees/list',
		name: 'network-financial-payment-fees-list',
		component: () => import('@/views/network/financial/payment-fees/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/financial/payment-refunds/list',
		name: 'network-financial-payment-refunds-list',
		component: () => import('@/views/network/financial/payment-refunds/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/financial/payment-periods/list',
		name: 'network-financial-payment-periods-list',
		component: () => import('@/views/network/financial/payment-periods/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/financial/payment-status/list',
		name: 'network-financial-payment-status-list',
		component: () => import('@/views/network/financial/payment-status/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/financial/payment-taxes/list',
		name: 'network-financial-payment-taxes-list',
		component: () => import('@/views/network/financial/payment-taxes/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/network/financial/payment-types/list',
		name: 'network-financial-payment-types-list',
		component: () => import('@/views/network/financial/payment-types/list.vue'),
		meta: {
			layout: 'content',
		},
	},
]

export default financial
