import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

// // // LIST ALL Companies Types
export const typeCreate = (params = '') => Http.post('/companies-types', params)
export const typesList = (params = '') => Http.get(`/companies-types${serialize(params)}`)
export const typeByID = (params = '') => Http.get(`/companies-types/${params}`)
export const typeUpdate = (params = '') => Http.put(`/companies-types/${params.id}`, params)
export const typeDestroy = (params = '') => Http.delete(`/companies-types/${params.id}`)

// // COMPANIES
// // // LIST ALL COMPANYS
export const companiesList = (params = '') => Http.get(`/company${serialize(params)}`)
export const companiesByID = (params = '') => Http.get(`/company/${params}`)
export const newCompany = (params = '') => Http.post('/company', params)
export const updateCompany = (params = '') => Http.put(`/company/${params.id}`, params)
export const deleteCompanyByID = (params = '') => Http.delete(`/company/${params.id}`)

// // // LIST ALL Companies Sizes
export const sizeCreate = (params = '') => Http.post('/companies-sizes', params)
export const sizesList = (params = '') => Http.get(`/companies-sizes${serialize(params)}`)
export const sizeByID = (params = '') => Http.get(`/companies-sizes/${params}`)
export const sizeUpdate = (params = '') => Http.put(`/companies-sizes/${params.id}`, params)
export const sizeDestroy = (params = '') => Http.delete(`/companies-sizes/${params.id}`)