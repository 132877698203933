import {
	magazineByID,
	magazineCreate,
	magazineDestroy,
	magazineUpdate,
	magazinesList,
} from '@/services/agency/magazines.service'

export default {
	namespaced: true,
	state: {
		magazinesList: {
			meta: {
				pagination: {
					total: 0,
				},
			},
		},
		magazine: {},
		// STATUS Magazine
		magazinesStatus: 'pending',
		magazinesSaveStatus: 'init',
		magazinesDeleteStatus: 'init',
	},
	getters: {
		getMagazinesList(state) {
			return state.magazinesList
		},
		getMagazine(state) {
			return state.magazine
		},
		// NETWORK PLATFORMS
		getMagazinesDeleteStatus(state) {
			return state.magazinesDeleteStatus
		},
		getMagazinesSaveStatus(state) {
			return state.magazinesSaveStatus
		},
		getMagazinesStatus(state) {
			return state.magazinesStatus
		},
	},
	mutations: {
		MAGAZINES_LIST(state, value) {
			state.magazinesList = value
		},
		SET_MAGAZINE(state, value) {
			state.magazine = value
		},
		// STATUS NETWORK PLATFORMS
		MAGAZINES_STATUS(state, value) {
			state.magazinesStatus = value
		},
		MAGAZINE_SAVE_STATUS(state, value) {
			state.magazinesSaveStatus = value
		},
		MAGAZINE_DELETE_STATUS(state, value) {
			state.magazinesDeleteStatus = value
		},
	},
	actions: {
		async fetchMagazineList({ commit }, params) {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			commit('MAGAZINES_STATUS', 'pending')
			commit('MAGAZINES_LIST', {
				meta: {
					pagination: {
						total: 0,
					},
				},
			})
			try {
				const { data } = await magazinesList(params)
				commit('MAGAZINES_STATUS', 'init')
				commit('MAGAZINES_LIST', data)
				return data
			} catch (error) {
				commit('MAGAZINES_STATUS', 'init')
				throw error
			}
		},
		async fetchMagazineByID({ commit }, params) {
			commit('MAGAZINES_STATUS', 'pending')
			try {
				const { data } = await magazineByID(params)
				commit('MAGAZINES_STATUS', 'init')
				commit('SET_MAGAZINE', data.data)
				return data
			} catch (error) {
				commit('MAGAZINES_STATUS', 'init')

				return error
			}
		},
		async saveNewMagazine({ commit }, params) {
			commit('MAGAZINE_SAVE_STATUS', 'pending')
			try {
				const { data } = await magazineCreate(params)
				commit('MAGAZINE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('MAGAZINE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async updateMagazine({ commit }, params) {
			commit('MAGAZINE_SAVE_STATUS', 'pending')
			try {
				const { data } = await magazineUpdate(params)
				commit('MAGAZINE_SAVE_STATUS', 'init')
				return data
			} catch (error) {
				commit('MAGAZINE_SAVE_STATUS', 'init')
				throw error
			}
		},
		async deleteMagazineByID({ commit }, params) {
			commit('MAGAZINE_DELETE_STATUS', 'pending')
			try {
				const { data } = await magazineDestroy(params)
				commit('MAGAZINE_DELETE_STATUS', 'init')
				return data
			} catch (error) {
				commit('MAGAZINE_DELETE_STATUS', 'error')

				return error
			}
		},
	},
}
