import { serialize } from '@/@core/libs/serialize/util'
import Http from '@/services/httpCommunity'

export const awardsList = (params = '') => Http.get(`/awards/fetch${serialize(params)}`)
export const awardCreate = (params = '') => Http.post(`/awards/store`, params)
export const awardUpdate = (params = '') => Http.put(`/awards/${params.id}/update`, params)
export const awardDestroy = (params = '') => Http.delete(`/awards/${params.id}/destroy`)


export const categoriesList = (params = '') => Http.get(`/award/categories/fetch${serialize(params)}`)
export const categoryCreate = (params = '') => Http.post(`/award/categories/store`, params)
export const categoryUpdate = (params = '') => Http.put(`/award/categories/${params.id}/update`, params)
export const categoryDestroy = (params = '') => Http.delete(`/award/categories/${params.id}/destroy`)


export const getCompetitors = (
	params = {
		perPage: 10,
		page: 1,
		search: '',
		award_id: '',
	},
) => Http.get(`/award-categories-games/fetch${serialize(params)}`)

export const destroyCompetitor = (
	params = {
		id: null,
	},
) => Http.delete(`/aaward-categories-games/${params.id}/destroy`)

export const saveCompetitor = (
	params = {},
) => Http.post(`/award-categories-games/store`, params)

export const getCompetitor = (
	params = {
		competitorId: '',
	},
) => Http.get(`/award-categories-games/${params.competitorId}/show`)
